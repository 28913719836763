import { Label, Icon, Tooltip } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

interface OpenJawTooltipProps {
  airportName: string;
  variant?: 'default' | 'small';
}

export const OpenJawTooltip: React.FC<OpenJawTooltipProps> = ({
  airportName,
  variant = 'default',
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      content={t('differentReturnAirportMessage')}
      sx={{
        marginLeft: '4xs',
        padding: '4xs',
        backgroundColor: 'backgroundWarningmedium',
        borderRadius: '4',
      }}
    >
      <Label variant={variant === 'default' ? 'medium' : 'small'}>{airportName}</Label>
      <Icon
        name="Markers/Tooltip"
        size="16"
        sx={{
          marginLeft: '4xs',
          verticalAlign: 'text-top',
        }}
      />
    </Tooltip>
  );
};
