import { arrow, offset, shift, useFloating } from '@floating-ui/react';
import {
  Icon,
  Portal,
  TOOLTIP_SPACING_FROM_SCREEN_EDGE,
  useClickOutside,
} from '@loveholidays/design-system';
import React, { KeyboardEvent, useRef, useCallback, useState } from 'react';

import { arrowStyles, ARROW_SIZE, tooltipStyles } from './Popover';
import { ClassNameProps } from '@ComponentProps';
import { Key } from '@Core/Key';

interface TooltipProps extends ClassNameProps {
  label?: React.ReactNode;
  openOnHover?: boolean;
  openOnFocus?: boolean;
  ariaLabel?: string;
}

const defaultLabel = () => (
  <Icon
    name="Markers/TooltipFilled"
    size="20"
    sx={{ color: 'link' }}
  />
);

// @todo - relative positioning with safe checking of viewport sides

export const Tooltip: React.FC<TooltipProps> = ({
  className,
  children,
  label = defaultLabel(),
  openOnHover = true,
  openOnFocus = false,
  ariaLabel,
  as: Wrapper = 'button',
}) => {
  const arrowRef = useRef(null);
  const {
    x,
    y,
    reference: setTriggerRef,
    floating: setTooltipRef,
    strategy,
    update,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    refs: { reference: triggerRef, floating: tooltipRef },
  } = useFloating({
    placement: 'bottom',
    middleware: [
      offset(ARROW_SIZE / 2),
      shift({ padding: TOOLTIP_SPACING_FROM_SCREEN_EDGE }),
      arrow({ element: arrowRef }),
    ],
  });

  const [isOpened, setIsOpened] = useState(false);
  const triggerHoveredRef = useRef(false);
  const tooltipHoveredRef = useRef(false);
  const purposefullyOpenedRef = useRef(false);

  const setOpened = useCallback(() => {
    setIsOpened((prevIsOpened) => {
      if (!prevIsOpened) {
        update();
      }

      return true;
    });
  }, [setIsOpened, update]);

  const setClosed = useCallback(() => {
    if (!purposefullyOpenedRef.current) {
      setIsOpened(false);
    }
  }, [setIsOpened, purposefullyOpenedRef]);

  const hoverOutTrigger = useCallback(() => {
    triggerHoveredRef.current = false;
    if (openOnHover && !tooltipHoveredRef.current) {
      setClosed();
    }
  }, [triggerHoveredRef, openOnHover, setClosed]);

  const hoverInTrigger = useCallback(() => {
    triggerHoveredRef.current = true;
    if (openOnHover) {
      setOpened();
    }
  }, [triggerHoveredRef, openOnHover, setOpened]);

  const hoverOutTooltip = useCallback(() => {
    tooltipHoveredRef.current = false;
    if (openOnHover && !triggerHoveredRef.current) {
      setClosed();
    }
  }, [tooltipHoveredRef, openOnHover, triggerHoveredRef, setClosed]);

  const hoverInTooltip = useCallback(() => {
    tooltipHoveredRef.current = true;
  }, [tooltipHoveredRef]);

  const focusOut = useCallback(() => {
    if (openOnFocus) {
      setClosed();
    }
  }, [openOnFocus, setClosed]);

  const focusIn = useCallback(() => {
    if (openOnFocus) {
      setOpened();
    }
  }, [openOnFocus, setOpened]);

  const clickIn = useCallback(() => {
    if (purposefullyOpenedRef.current === true) {
      purposefullyOpenedRef.current = false;
      setClosed();
    } else {
      purposefullyOpenedRef.current = true;
      setOpened();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purposefullyOpenedRef, setOpened]);

  const clickOut = useCallback(() => {
    purposefullyOpenedRef.current = false;
    setClosed();
  }, [purposefullyOpenedRef, setClosed]);

  useClickOutside({
    ref: [triggerRef as React.MutableRefObject<HTMLElement | null>, tooltipRef],
    isActive: isOpened,
    onClick: clickOut,
  });

  return (
    <span
      data-id="tooltip-container"
      className={className}
      ref={setTriggerRef}
      onMouseEnter={hoverInTrigger}
      onMouseLeave={hoverOutTrigger}
    >
      <Wrapper
        data-id="tooltip-button"
        role="button"
        tabIndex={0}
        sx={{ verticalAlign: 'middle' }}
        onClick={clickIn}
        aria-label={ariaLabel}
        onKeyUp={(e: KeyboardEvent<HTMLDivElement>) => {
          if (e.key === Key.Enter) {
            clickIn();
          }
        }}
        onFocus={focusIn}
        onBlur={focusOut}
      >
        {label}
      </Wrapper>
      {isOpened && (
        <Portal>
          <div
            onMouseEnter={hoverInTooltip}
            onMouseLeave={hoverOutTooltip}
            ref={setTooltipRef}
            data-id="tooltip-content"
            sx={{
              position: strategy,
              top: y,
              left: x,
              ...tooltipStyles,
            }}
          >
            <div
              ref={arrowRef}
              sx={{
                position: 'absolute',
                left: arrowX,
                top: arrowY,
                ...arrowStyles,
              }}
            />
            <div>{children}</div>
          </div>
        </Portal>
      )}
    </span>
  );
};
