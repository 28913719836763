import React from 'react';

import { ClassNameProps } from '@ComponentProps';

const Dot = () => (
  <div
    sx={{
      borderRadius: 'rounded',
      padding: '5xs',
      backgroundColor: 'backgroundBlack',
    }}
  />
);

export const FlightTimeline: React.FC<ClassNameProps> = ({ className }) => (
  <div
    className={className}
    sx={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginY: '3xs',
    }}
  >
    <Dot />
    <div
      sx={{
        marginY: '4xs',
        width: '1px',
        flexGrow: 1,
        backgroundColor: 'strokeLightneutral',
      }}
    />
    <Dot />
  </div>
);
