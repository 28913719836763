import { Label, Tooltip } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import dayjs from 'dayjs';
import React from 'react';

import { useDate } from '@Dates/useDateHook';

interface FlightDateOffsetProps {
  departureDate: string;
  arrivalDate: string;
}

export const FlightDateOffset: React.FC<FlightDateOffsetProps> = ({
  departureDate,
  arrivalDate,
}) => {
  const { t } = useTranslation();
  const { formatCalendarDate, getDaysDifference } = useDate();

  const offset = getDaysDifference(dayjs(departureDate), dayjs(arrivalDate));

  return offset > 0 ? (
    <Tooltip
      content={t('flightTicket.arrives', {
        date: formatCalendarDate(arrivalDate),
      })}
    >
      <Label
        variant="extrasmallbold"
        sx={{ verticalAlign: 'top' }}
      >
        +{offset}
      </Label>
    </Tooltip>
  ) : null;
};
