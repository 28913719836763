import { Label, LabelVariants } from '@loveholidays/design-system';
import React from 'react';

import { OpenJawTooltip } from './OpenJawTooltip';
import { FlightDateOffset } from '@Components/FlightDateOffset/FlightDateOffset';

interface TimeAndAirportProps {
  airportName: string;
  time: string;
  dates?: {
    departure: string;
    arrival: string;
  };
  openJaw?: boolean;
  variant?: 'default' | 'small';
}

interface Variants {
  timeVariant: LabelVariants;
  airportVariant: LabelVariants;
}

const variants: Record<string, Variants> = {
  default: {
    timeVariant: 'largebold',
    airportVariant: 'medium',
  },
  small: {
    timeVariant: 'small',
    airportVariant: 'small',
  },
};

export const TimeAndAirport: React.FC<TimeAndAirportProps> = ({
  airportName,
  time,
  dates,
  openJaw,
  variant = 'default',
}) => {
  const labelVariant = variants[variant];

  return (
    <div
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: '4xs',
      }}
    >
      <Label variant={labelVariant.timeVariant}>{time}</Label>
      <div sx={{ width: (t) => t.space['2xs'] }}>
        {dates && (
          <FlightDateOffset
            departureDate={dates.departure}
            arrivalDate={dates.arrival}
          />
        )}
      </div>
      {openJaw ? (
        <OpenJawTooltip
          airportName={airportName}
          variant={variant}
        />
      ) : (
        <Label
          variant={labelVariant.airportVariant}
          sx={{
            paddingLeft: '3xs',
            color: 'textDimmedheavy',
          }}
        >
          {airportName}
        </Label>
      )}
    </div>
  );
};
