import { Icon, IconProps, Badge, Label } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment } from 'react';

import { FlightTimeline } from './FlightTimeline';
import { TimeAndAirport } from './TimeAndAirport';
import { OfferFlight, OfferFlightsLeg } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { AirlineLogo } from '@Components/AirlineLogo/AirlineLogo';
import { useDate } from '@Dates/useDateHook';

interface ConnectInAirportBadgeProps {
  currentIndex: number;
  segments: OfferFlight[];
}

const ConnectInAirportBadge: React.FC<ConnectInAirportBadgeProps> = ({
  currentIndex,
  segments,
}) => {
  const { t } = useTranslation();

  if (currentIndex >= segments.length - 1) {
    // last segment
    return null;
  }
  if (segments[currentIndex].arrivalAirport.id !== segments[currentIndex + 1].departureAirport.id) {
    // arrival != departure
    return null;
  }

  return (
    <Badge
      variant="Neutral"
      size="24"
    >
      {t('flightSegment.connectInAirport')}
    </Badge>
  );
};

interface MultiStopPopoverContentProps extends ClassNameProps {
  leg: OfferFlightsLeg;
  direction?: 'inbound' | 'outbound';
  icon: IconProps['name'];
}

export const MultiStopPopoverContent: React.FC<MultiStopPopoverContentProps> = ({
  leg,
  icon,
  className,
  direction,
}) => {
  const { t } = useTranslation();
  const { formatCalendarDate, formatLocalTime, formatDuration } = useDate();

  return (
    <div
      className={className}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2xs',
      }}
    >
      {leg.segments.items.map((segment, index, segments) => (
        <Fragment key={index}>
          <div sx={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
            <div sx={{ display: 'flex' }}>
              <Icon
                name={icon}
                size="20"
                sx={{
                  marginRight: '2xs',
                }}
              />
              <span sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <Label
                  variant="smallbold"
                  sx={{
                    marginBottom: '5xs',
                  }}
                >
                  {formatCalendarDate(segment.departureDate)}
                </Label>
                {direction && (
                  <Label
                    variant="small"
                    sx={{ color: 'textDimmedheavy' }}
                  >
                    {t(direction)}
                  </Label>
                )}
              </span>
            </div>
            <span sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <AirlineLogo
                carrierCode={segment.operatingCarrier}
                carrierName={segment.operatingCarrierName}
              />
              <Label
                variant="medium"
                sx={{ color: 'textDimmedheavy', marginTop: '4xs' }}
              >
                {segment.flightNumber}
              </Label>
            </span>
          </div>

          <div
            sx={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              rowGap: '3xs',
            }}
          >
            <FlightTimeline
              sx={{
                gridRow: '1 / span 2',
                marginRight: 'xs',
                marginLeft: '3xs',
              }}
            />
            <TimeAndAirport
              airportName={segment.departureAirport.name}
              time={formatLocalTime(segment.departureTime)}
            />
            <TimeAndAirport
              airportName={segment.arrivalAirport.name}
              time={formatLocalTime(segment.arrivalTime)}
              dates={{ departure: segment.departureDate, arrival: segment.arrivalDate }}
            />
          </div>
          {segment.stopoverDuration && (
            <div
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '3xs',
              }}
            >
              <Badge
                variant="Neutral"
                size="24"
                icon="Content/InputTime"
                iconPosition="left"
                sx={{
                  marginRight: '4xs',
                }}
              >
                {t('flightSegment.stop', { duration: formatDuration(segment.stopoverDuration) })}
              </Badge>
              <ConnectInAirportBadge
                currentIndex={index}
                segments={segments}
              />
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};
